<template>
  <el-dialog
    v-model="dialogFormVisible"
    :title="title"
    width="520px"
    @close="handleClose"
  >
    <el-form label-width="80px" :model="state.form">
      <el-form-item label="票种名称">
        <el-input v-model="state.form.ticketName" disabled />
      </el-form-item>
      <el-form-item label="票种备注">
        <el-input v-model="state.form.remarkPc" placeholder="请输入票种备注" />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click.stop="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSave">保存</el-button>
    </template>
  </el-dialog>
</template>

<script setup>
  import { reactive, ref, getCurrentInstance } from 'vue'
  import { updateRemark } from '@/api/exhibitionManage'
  import { debounce } from '@/utils/debounce'
  const props = defineProps({
    title: {
      type: String,
      default: '编辑',
    },
  })
  const { proxy } = getCurrentInstance()
  const dialogFormVisible = ref(false)
  const state = reactive({
    form: {},
  })
  // 编辑更新
  const putUpdateRemark = async () => {
    let data = {
      remarkPc: state.form.remarkPc,
      ticketCode: state.form.ticketCode,
    }
    let resp = await updateRemark(data)
  }
  const emit = defineEmits(['noticeRefresh'])
  // 关闭
  const handleClose = () => {
    dialogFormVisible.value = false
  }
  // 打开
  const handleOpen = (row = {}) => {
    state.form = row
    dialogFormVisible.value = true
  }
  // 保存
  const handleSave = debounce(() => {
    putUpdateRemark()
    proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
    emit('noticeRefresh')
    handleClose()
  })
  defineExpose({
    handleClose,
    handleOpen,
  })
</script>
